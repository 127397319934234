// Roadmap.js
import React, { useRef, useEffect } from 'react';
import SkillItem from './SkillItem';
import ExperienceItem from './ExperienceItem';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import '../css/Roadmap.css';

gsap.registerPlugin(ScrollTrigger);

const Roadmap = () => {
    const skills = [
        {
            title: 'HTML/CSS',
            description: 'Создание адаптивных веб-страниц.',
            experiences: [
                { company: 'Компания A', role: 'Frontend Developer', duration: '2020-2021' },
            ],
        },
        {
            title: 'JavaScript',
            description: 'Разработка интерактивных интерфейсов.',
            experiences: [
                { company: 'Компания B', role: 'JavaScript Developer', duration: '2021-2022' },
            ],
        },
        {
            title: 'React',
            description: 'Построение сложных SPA приложений.',
            experiences: [
                { company: 'Компания C', role: 'Senior React Developer', duration: '2022-2024' },
            ],
        },
        // Добавь больше навыков по необходимости
    ];

    const roadmapRef = useRef(null);
    const svgRef = useRef(null);

    useEffect(() => {
        const roadmapElement = roadmapRef.current;
        const svgElement = svgRef.current;
        const skillElements = roadmapElement.querySelectorAll('.skill-item');

        // Функция для рисования линий между элементами
        const drawLines = () => {
            // Очистка SVG перед рисованием
            while (svgElement.firstChild) {
                svgElement.removeChild(svgElement.firstChild);
            }

            skillElements.forEach((skill, index) => {
                if (index < skillElements.length - 1) {
                    const currentRect = skill.getBoundingClientRect();
                    const nextRect = skillElements[index + 1].getBoundingClientRect();

                    const containerRect = roadmapElement.getBoundingClientRect();

                    const startX = currentRect.right - containerRect.left;
                    const startY = currentRect.top - containerRect.top + currentRect.height / 2;
                    const endX = nextRect.left - containerRect.left;
                    const endY = nextRect.top - containerRect.top + nextRect.height / 2;

                    // Создание линии
                    const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
                    line.setAttribute('x1', startX);
                    line.setAttribute('y1', startY);
                    line.setAttribute('x2', startX); // Начальная точка совпадает с x1
                    line.setAttribute('y2', startY); // Начальная точка совпадает с y1
                    line.setAttribute('stroke', '#3f51b5');
                    line.setAttribute('stroke-width', '2');
                    line.setAttribute('class', 'roadmap-line');

                    svgElement.appendChild(line);

                    // Анимация линии с помощью GSAP
                    gsap.to(line, {
                        duration: 1.5,
                        attr: { x2: endX, y2: endY },
                        ease: 'power3.out',
                        scrollTrigger: {
                            trigger: skill,
                            start: 'top center',
                            toggleActions: 'play none none reverse',
                        },
                    });

                    // Добавление точек соединения
                    const startCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
                    startCircle.setAttribute('cx', startX);
                    startCircle.setAttribute('cy', startY);
                    startCircle.setAttribute('r', '5');
                    startCircle.setAttribute('fill', '#3f51b5');
                    startCircle.setAttribute('class', 'roadmap-point');

                    const endCircle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
                    endCircle.setAttribute('cx', endX);
                    endCircle.setAttribute('cy', endY);
                    endCircle.setAttribute('r', '5');
                    endCircle.setAttribute('fill', '#3f51b5');
                    endCircle.setAttribute('class', 'roadmap-point');

                    svgElement.appendChild(startCircle);
                    svgElement.appendChild(endCircle);

                    // Анимация точек
                    gsap.fromTo(
                        [startCircle, endCircle],
                        { scale: 0 },
                        {
                            scale: 1,
                            duration: 1,
                            delay: 1.5,
                            ease: 'back.out(1.7)',
                            scrollTrigger: {
                                trigger: skill,
                                start: 'top center',
                                toggleActions: 'play none none reverse',
                            },
                        }
                    );
                }
            });
        };

        drawLines();

        // Перерисовываем линии при изменении размера окна
        window.addEventListener('resize', drawLines);

        return () => {
            window.removeEventListener('resize', drawLines);
        };
    }, [skills]);

    return (
        <div className="roadmap-container" ref={roadmapRef}>
            {skills.map((skill, index) => (
                <div key={index} className="roadmap-item-wrapper">
                    <SkillItem
                        title={skill.title}
                        description={skill.description}
                    />
                    <div className="experience-wrapper">
                        {skill.experiences.map((exp, idx) => (
                            <ExperienceItem
                                key={idx}
                                company={exp.company}
                                role={exp.role}
                                duration={exp.duration}
                            />
                        ))}
                    </div>
                </div>
            ))}

            {/* SVG для рисования маршрутов */}
            <svg className="roadmap-svg" ref={svgRef}></svg>
        </div>
    );
};

export default Roadmap;
