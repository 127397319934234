// SkillItem.js
import React, { forwardRef, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import '../css/Roadmap.css';

gsap.registerPlugin(ScrollTrigger);

const SkillItem = forwardRef(({ title, description }, ref) => {
  const skillRef = useRef(null);

  useEffect(() => {
    const element = skillRef.current;
    gsap.fromTo(
      element,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: element,
          start: 'top 80%',
          toggleActions: 'play none none reverse',
        },
      }
    );
  }, []);

  return (
    <div className="skill-item" ref={skillRef}>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
});

export default SkillItem;
