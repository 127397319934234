import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../hooks/useLanguage';

const Switch = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useLanguage();
  const [isChecked, setIsChecked] = useState(language === 'ua');

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  const handleToggle = () => {
    const newLanguage = isChecked ? 'en' : 'ua';
    setLanguage(newLanguage);
    setIsChecked(!isChecked);
  };


  return (
    <div className="flex items-center justify-center p-4">
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleToggle}
          className="sr-only peer outline-none"
        />
        <div className="w-16 h-8 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-[112%] peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-7 after:w-7 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
          <span className={`absolute left-2 top-1.5 text-sm font-medium text-white transition-opacity ${isChecked ? 'opacity-100' : 'opacity-0'}`}>
            EN
          </span>
          <span className={`absolute right-2 top-1.5 text-sm font-medium text-black dark:text-white transition-opacity ${isChecked ? 'opacity-0' : 'opacity-100'}`}>
            UA
          </span>
        </div>
      </label>
    </div>
  );
};

export default Switch;
