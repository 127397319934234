import React from 'react';
import { motion, useAnimation } from 'framer-motion';

const ExperienceCard = ({ job, index, inuse }) => {
  const controls = useAnimation();
  const formattedText = job.case.description.replace(/\n/g, '<br />');
  return <motion.div
    key={index}
    initial={{ opacity: 0, x: index % 2 === 0 ? 100 : -100 }}
    whileInView={{ opacity: 1, x: 0 }}
    viewport={{ once: true, margin: '0px 0px -50% 0px', threshold: 0.5 }}
    transition={{ duration: 0.5 }}
    onAnimationComplete={() => {
      controls.start({ opacity: 1, y: 0 });
    }}
    className="mb-6 border-b last:border-b-0 pb-4"
  >
    <h3 className="text-xl md:text-2xl font-bold">{job.position} at {job.company}</h3>
    <p className="italic text-lg md:text-xl">{job.date}</p>
    <div className="ml-4 text-xl md:text-2xl mt-2 ">
      {job.responsibilities.map((resp, i) => (
        <p key={i} className="mb-2">{resp}</p>
      ))}
    </div>
    {/* technologies in use */}
    <div className="mt-4">
      <h4 className="text-lg md:text-xl font-semibold">{inuse}</h4>
      <div className='text-orange-400 flex flex-wrap gap-2 mt-2'>
        {job.technologies.map((tech, i) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
            transition={{ delay: i * 0.1, duration: 0.5 }}
            className="mb-2 py-1 px-4 border border-orange-400 rounded-lg opacity-0">{tech}</motion.div>
        ))}
      </div>
      {/* Interested case */}
      <motion.div
        key={index}
        initial={{ opacity: 0, y: 20 }}
        animate={controls}
        transition={{ duration: 0.5 }}
        className="mb-2 py-1 text-xl md:text-2xl opacity-0">
        <div className="mt-4">
          <h4 className="font-semibold">{job.case.title}</h4>
          <div className='mt-2'>
            <div className='px-4' dangerouslySetInnerHTML={{ __html: formattedText }}></div>
          </div>
        </div>
      </motion.div>
    </div>
  </motion.div>
}
const Experience = ({ experience }) => {

  return (
    <section className="p-6 rounded-lg mb-4 bg-gray-100 dark:bg-gray-900 text-black dark:text-white ">
      <h2 className="text-2xl md:text-3xl font-semibold mb-4">{experience.title}</h2>
      {experience.jobs.map((job, index) => (
        <ExperienceCard job={job} index={index} inuse={experience.technologies} />
      ))}
    </section>
  );
};

export default Experience;
