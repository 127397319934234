import React from 'react';

const Summary = ({ summary }) => {
    const formattedSummary = summary.content.replace(/\n/g, '<br />');
    return (
        <section className="text-black dark:text-white p-6 bg-gray-100 dark:bg-gray-900 rounded-lg mb-4">
            <h2 className="text-2xl md:text-3xl font-semibold mb-4">{summary.title}</h2>
            <p className="text-xl md:text-2xl" dangerouslySetInnerHTML={{ __html: formattedSummary }}></p>
        </section>
    );
};

export default Summary;
