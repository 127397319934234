import { useEffect, useState } from 'react';

export const useLanguage = () => {
  const [language, setLanguage] = useState('ua');

  const setLang = (lang) => {
    window.localStorage.setItem('language', lang);
    setLanguage(lang);
  };

  useEffect(() => {
    const localLang = window.localStorage.getItem('language');
    localLang && setLanguage(localLang);
  }, []);

  return [language, setLang];
};
