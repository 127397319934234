import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import Header from './components/Header';
import Summary from './components/Summary';
import Skills from './components/Skills';
import Experience from './components/Experience';
import Education from './components/Education';
import Switch from './components/Switch';
import ThemeSwitch from './components/ThemeSwitch';
import AnimatedBlock from './components/AnimatedBlock';
import Roadmap from './components/Roadmap';
const App = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-7xl mx-auto my-8 p-4 rounded-lg shadow-lg overflow-x-hidden">
      <AnimatedBlock>
        <div className="flex justify-between">
          <Switch />
          <ThemeSwitch />
        </div>
      </AnimatedBlock>
      <AnimatedBlock>
        <Header name={t('header.name')} contact={t('header.contact', { returnObjects: true })} />
      </AnimatedBlock>
      <AnimatedBlock>
        <Summary summary={t('summary', { returnObjects: true })} />
      </AnimatedBlock>
      <AnimatedBlock>
        <Skills skills={t('skills', { returnObjects: true })} />
      </AnimatedBlock>
      <AnimatedBlock>
        <Experience experience={t('experience', { returnObjects: true })} />
      </AnimatedBlock>
      <AnimatedBlock>
        <Education education={t('education', { returnObjects: true })} />
      </AnimatedBlock>

      {/* <Roadmap /> */}
    </div>
  );
};

export default App;
