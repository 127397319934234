// ExperienceItem.js
import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import '../css/Roadmap.css';

gsap.registerPlugin(ScrollTrigger);

const ExperienceItem = ({ company, role, duration }) => {
  const experienceRef = useRef(null);

  useEffect(() => {
    const element = experienceRef.current;
    gsap.fromTo(
      element,
      { opacity: 0, x: -50 },
      {
        opacity: 1,
        x: 0,
        duration: 1,
        ease: 'power3.out',
        scrollTrigger: {
          trigger: element,
          start: 'top 80%',
          toggleActions: 'play none none reverse',
        },
      }
    );
  }, []);

  return (
    <div className="experience-item" ref={experienceRef}>
      <h4>{role} at {company}</h4>
      <span>{duration}</span>
    </div>
  );
};

export default ExperienceItem;
