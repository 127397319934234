import React, { useState, useEffect } from 'react';

const ThemeSwitch = () => {
    const [isDarkMode, setIsDarkMode] = useState(() => {
        return localStorage.getItem('theme') === 'dark';
    });

    useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark');
            localStorage.setItem('theme', 'dark');
        } else {
            document.documentElement.classList.remove('dark');
            localStorage.setItem('theme', 'light');
        }
    }, [isDarkMode]);

    const handleToggle = () => {
        setIsDarkMode(!isDarkMode);
    };

    return (
        <div className="flex items-center justify-center p-4">
            <label className="relative inline-flex items-center cursor-pointer overflow-hidden">
                <input
                    type="checkbox"
                    checked={isDarkMode}
                    onChange={handleToggle}
                    className="sr-only peer"
                />
                <div className={`${isDarkMode ? 'bg-gradient-to-b from-[#091236] to-[#1E215D]' : 'bg-gradient-to-b from-[#39598A] to-[#79D7ED]'} w-16 h-8 bg-gray-200 peer-focus:outline-none rounded-full peer dark:bg-gray-700 dark:border-gray-600 peer-checked:bg-blue-600`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`text-white absolute right-1.5 w-5 h-5 ${isDarkMode ? 'top-1.5' : '-top-[25px]'}`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`absolute left-1.5 w-5 h-5 text-yellow-500 ${isDarkMode ? 'top-[35px]' : 'top-1.5'}`}                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
                    </svg>




                </div>
            </label>
        </div>
    );
};

export default ThemeSwitch;
