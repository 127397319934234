import React from 'react';
import { motion } from 'framer-motion';

const Skills = ({ skills }) => {
    return (
        <section className="p-6 bg-white rounded-lg mb-4 bg-gray-100 dark:bg-gray-900">
            <h2 className="text-black dark:text-white text-2xl md:text-3xl font-semibold mb-4">{skills.title}</h2>
            <div className='text-orange-400 flex flex-wrap gap-2'>{skills.list.map((skill, index) => (
                <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1, duration: 0.5 }}
                    viewport={{ once: true }}
                    className="text-lg md:text-2xl mb-2 py-2 px-4 border border-orange-400 rounded-lg"
                >
                    {skill}</motion.div>
            ))}
            </div>
        </section>
    );
};

export default Skills;
