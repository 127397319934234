import React from 'react';
import { motion } from 'framer-motion';

const Education = ({ education }) => {
    return (
        <section className="p-6 bg-white rounded-lg mb-4 dark:bg-gray-900 text-black dark:text-white">
            <h2 className="text-2xl md:text-3xl font-semibold mb-4">{education.title}</h2>
            {education.schools.map((school, index) => (
                <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: [0, -20, 10, -10, 5, 0] }}
                    viewport={{ once: true }}
                    transition={{ delay: index * 0.1, duration: 0.8 }}
                    key={index} className="mb-4 border-b last:border-b-0 pb-4">

                    <h3 className="md:text-xl font-bold">{school.institution}</h3>
                    <p className="italic md:text-lg">{school.degree}, {school.year}</p>
                </motion.div>
            ))}
        </section>
    );
};

export default Education;
