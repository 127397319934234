import React from 'react';

const Header = ({ name, contact }) => {
    return (
        <header className="text-black dark:text-white p-6 text-left">
            <h1 className="md:text-5xl text-2xl font-bold">{name}</h1>
            <div className="mt-2 text-xl md:text-2xl flex-col md:flex-row flex gap-4 md:divide-x divide-gray-400">
                <a className='underline underline-offset-4 '
                    href={`mailto:${contact.email}`}>{contact.email}</a>
                <a className='underline underline-offset-4  md:px-4'
                    href={`tel:${contact.phone.replace(
                        /[\s()-]/g,
                        ''
                    )}`}>{contact.phone}</a>
                <div className='md:px-4'>{contact.city}</div>
            </div>
        </header>
    );
};

export default Header;
